function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/miscellaneous/_components/${view}.vue`)
}

let routes = [
    {
        name: 'testimonials',
        path: '/testimonials',
        component: loadView('Testimonial/index'),
        meta: {
            auth: true,
            permission: 'testimonial-read'
        }
    },
    {
        name: 'notice',
        path: '/notice',
        component: loadView('Notice/index'),
        meta: {
            auth: true,
            permission: 'notice-read'
        }
    },
    {
        name: 'album',
        path: '/album',
        component: loadView('Album/index'),
        meta: {
            auth: true,
            permission: 'album-read'
        }
    },
    {
        name: 'album-image',
        path: '/album/:albumId/images',
        component: loadView('Album/images'),
        meta: {
            auth: true,
            permission: 'album-read'
        }
    },
    {
        name: 'slider',
        path: '/slider',
        component: loadView('Slider/index'),
        meta: {
            auth: true,
            permission: 'slider-read'
        }
    },
    {
        name: 'quote',
        path: '/quote',
        component: loadView('Quote/index'),
        meta: {
            auth: true,
            permission: 'school-create'
        }
    },{
        name: 'service',
        path: '/service',
        component: loadView('Service/index'),
        meta: {
            auth: true,
            permission: 'notice-read'
        }
    },{
        name: 'media',
        path: '/media',
        component: loadView('Media/index'),
        meta: {
            auth: true,
            permission: 'notice-read'
        }
    },{
        name: 'news',
        path: '/news',
        component: loadView('News/index'),
        meta: {
            auth: true,
            permission: 'notice-read'
        }
    },{
        name: 'admisson-request',
        path: '/admisson-request',
        component: loadView('AdmissionRequest/index'),
        meta: {
            auth: true,
            // permission: 'notice-read'
        }
    },{
        name: 'admisson-setting',
        path: '/admisson-setting',
        component: loadView('AdmissionRequest/setting'),
        meta: {
            auth: true,
            // permission: 'notice-read'
        }

    },{
        name: 'photo',
        path: '/photo',
        component: loadView('Photo/index'),
        meta: {
            auth: true,
            // permission: 'notice-read'
        }
    },{
        name: 'video',
        path: '/video',
        component: loadView('Video/index'),
        meta: {
            auth: true,
            // permission: 'notice-read'
        }
    }
];

export default routes;